/* slick carousel CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  overflow: hidden; 
}

* {
  scrollbar-width: unset !important;
}

#root {
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightgray; 
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
.search-input {
  border-color: none;
  border-width: 0px;
}
.search-input:focus-visible {
  border-color: none;
  border-width: 0px;
  outline: none;
}

.image-container {
  /*width: 300px;  /* You can adjust the width */
  overflow: hidden; /* Hides any overflowing image */
  transform: scale(1.5);
}
.sidebar {
  /* width: 350px; */
  /* height: 100vh;
  background-color: transparent;
  box-shadow: 10px 0 5px -8px rgba(0, 0, 0, 0.1); */
  /* font-family:; */
  transition: width 0.5s ease;
}
.leftCart {
  border-radius: 20px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.search-input-bottom {
  position: fixed;
  background: rgb(243, 242, 236);
  width: 95%;
  padding-bottom: 15px;
  bottom: 0;
  padding-top: 10px;
  max-width: 865px;
}
.chat-container {
  display: flex;
  flex-direction: column;
  max-height: 34vh;
  width: 100%;
}

.messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 15px;
  max-width: 60%;
  font-size: 14px;
  position: relative;
}

.sent-message {
  border-top-right-radius: 0;
  flex-direction: row;
  margin-top: 15px;
}

.receive-message {
  border-top-left-radius: 0;
  margin-top: 15px;
}

.MuiDrawer-paper{
  min-width: 365px;
}
