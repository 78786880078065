/* General styles */
body {
  font-family: Arial, sans-serif;
}

.header {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* padding: 15px; */
  background-color: #f0eded;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  justify-content: space-around;
}

.image-container img {
  cursor: pointer;
}

.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
}

.hamburger .line {
  width: 30px;
  height: 3px;
  background-color: white;
}

/* Mobile styles */
@media (max-width: 768px) {
  .nav-links {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fffbfb;
    height: 100vh;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .nav-links.active {
    transform: translateX(0);
  }

  .hamburger {
    display: flex;
  }
}
